module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-scroll-reveal/gatsby-browser.js'),
      options: {"plugins":[],"threshold":0.01,"once":true,"disable":false,"selector":"[data-sal]","animateClassName":"sal-animate","disabledClassName":"sal-disabled","rootMargin":"0% 50%","enterEventName":"sal:in","exitEventName":"sal:out"},
    },{
      plugin: require('../node_modules/gatsby-plugin-apollo/gatsby-browser.js'),
      options: {"plugins":[],"uri":"https://graphql.contentful.com/content/v1/spaces/3tapvt0o6lko/environments/master","headers":{"Authorization":"Bearer XzMyz2dbSnWw8Mfcq4c9g9ZBZ1HYW5FR39Y2DMyH0so"}},
    },{
      plugin: require('../node_modules/gatsby-plugin-sentry/gatsby-browser.js'),
      options: {"plugins":[],"dsn":"https://d6c4ef2ab6d74af0a316712e1529ce49@o1382103.ingest.sentry.io/6696454","environment":"production","enabled":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"ALLPRESS","short_name":"allpress","start_url":"/","background_color":"#EFE7DD","theme_color":"#EFE7DD","icon":"src/images/icon.png","display":"standalone","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"aaabccd5886e1d4e3e396436e86c1e2d"},
    },{
      plugin: require('../node_modules/gatsby-plugin-intl/gatsby-browser.js'),
      options: {"plugins":[],"path":"/codebuild/output/src1792740065/src/src/intl","languages":["en","ja"],"defaultLanguage":"en"},
    },{
      plugin: require('../node_modules/gatsby-plugin-anchor-links/gatsby-browser.js'),
      options: {"plugins":[],"offset":80},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
